<template>
    <div>
      <div v-if="successMessage" class="alert alert-success" style="color: rgb(5, 20, 48)">
        {{ successMessage }}
      </div>
      <b-card>
        <b-row>
            <div class="col-12 mt-16">
                    <b-form @submit.prevent="addUser" v-if="show">
                        <div style="
                background-color: rgb(97, 116, 152);
                height: 32px;
                border-radius: 4px;
              ">
                            <h4 style="
                  color: rgb(223, 227, 238);
                  margin-left: 5px;
                  font-weight: bold;
                ">
                                Category Information
                            </h4>
                        </div>
                        <div class="row">
                            <div class="col-md-4 col-12">
                                <b-form-group id="input-group-1" label=" Category Name:" label-for="title">
                                    <b-form-input id="title" type="text" placeholder="Enter  name" autocomplete="off"
                                        v-model="title" disabled>
                                    </b-form-input>
                                </b-form-group>
                            </div>

                            <div class="col-md-4 col-12">
                                <b-form-group id="input-group-2" label="Category Picture:" label-for="image">
                                    <div style="margin-left: 3px; margin-bottom: 15px">
                                        <img :src="'https://wossbackend.singsavatech.com/' + image
                                            " alt="Brand Picture" width="100" height="100" />
                                    </div>
                                </b-form-group>
                            </div>
                        </div>
                    </b-form>
                </div>
        </b-row>
      </b-card>
    </div>
  </template>
  
  <script>
  import {
    BRow,
    BCol,
    BCard,
    BButton,
    BForm,
    BFormGroup,
    BFormCheckboxGroup,
    BFormCheckbox,
    BFormSelect,
    BFormInput,
    BSpinner,
  } from "bootstrap-vue";
  import axios from "axios";
  import { BToast } from "bootstrap-vue";
import code from "@/view/components/data-entry/form/code";
  
  export default {
    data() {
      return {
        show: true,
            // Add Driver
            title: "",
            image: "",
          
            
        
      };
    },
    components: {
        BRow,
        BCol,
        BCard,
        BButton,
        BForm,
        BFormGroup,
        BFormCheckboxGroup,
        BFormCheckbox,
        BFormSelect,
        BFormInput,
        BToast, // Add this line
        BSpinner,
    },
    created() {
        
        const userId = this.$route.params.id;
        axios
            .get(`categoryShow/${userId}`)
            .then((response) => {
                this.editedUser = response.data.data;
                // Set the data properties with values from editedUser
                this.title = this.editedUser.title;
                this.image = this.editedUser.image;
           
            })
            .catch((error) => {
                console.error("Error fetching user data:", error);
            });
    },
    mounted() {
    this.fetchData(); // Fetch data when the component is mounted
  },
    methods: {
        showMsgBoxOne() {
            debugger;
        },
        onSubmit(event) {
            event.preventDefault();
            alert(JSON.stringify(this.form));
            // debugger
        },
       
        onProfilePictureChange(event) {
            const file = event.target.files[0];
            if (file) {
                // Set the selected file to the data property
                this.image = file;
            }
        },

        codeClick() {
            this.codeActive = !this.codeActive;
        },

        saveOwnCar() {
            this.showModal = false;
        },
    },
  };
  </script>
  